body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*
 * Icon Font SivanPlusFont
 * Made with love by Icons8 [ https://icons8.com/ ] using FontCustom [ https://github.com/FontCustom/fontcustom ]
 *
 * Contacts:
 *    [ https://icons8.com/contact ]
 *
 * Follow Icon8 on
 *    Twitter [ https://twitter.com/icons_8 ]
 *    Facebook [ https://www.facebook.com/Icons8 ]
 *    Google+ [ https://plus.google.com/+Icons8 ]
 *    GitHub [ https://github.com/icons8 ]
 */

@font-face {
  font-family: "SivanPlusFont";
  src: url("./fonts/SivanPlusFont_50c939aa4ac3001d0d9be1c880c9cc1c.eot");
  src: url("./fonts/SivanPlusFont_50c939aa4ac3001d0d9be1c880c9cc1c.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/SivanPlusFont_50c939aa4ac3001d0d9be1c880c9cc1c.woff2")
      format("woff2"),
    url("./fonts/SivanPlusFont_50c939aa4ac3001d0d9be1c880c9cc1c.woff")
      format("woff"),
    url("./fonts/SivanPlusFont_50c939aa4ac3001d0d9be1c880c9cc1c.ttf")
      format("truetype"),
    url("./fonts/SivanPlusFont_50c939aa4ac3001d0d9be1c880c9cc1c.svg#SivanPlusFont")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: "SivanPlusFont";
    src: url("./fonts/SivanPlusFont_50c939aa4ac3001d0d9be1c880c9cc1c.svg#SivanPlusFont")
      format("svg");
  }
}

[data-icons8]:before {
  content: attr(data-icons8);
}

.MuiInputBase-root {
  direction: rtl;
}

[data-icons8]:before,
.icons8-about:before,
.icons8-active-state:before,
.icons8-add-user-male:before,
.icons8-address-book:before,
.icons8-administrator-male:before,
.icons8-angel:before,
.icons8-ask-question:before,
.icons8-attach:before,
.icons8-baby-feet:before,
.icons8-bag-diagonal-view:before,
.icons8-bank:before,
.icons8-beach:before,
.icons8-bench-press:before,
.icons8-bill:before,
.icons8-book:before,
.icons8-book-with-calendar:before,
.icons8-boy:before,
.icons8-bugle:before,
.icons8-businessman:before,
.icons8-businesswoman:before,
.icons8-businesswoman-2:before,
.icons8-cancel:before,
.icons8-cancel-2:before,
.icons8-cancel-2-2:before,
.icons8-cancel-subscription:before,
.icons8-cancel-with-usd:before,
.icons8-car:before,
.icons8-change-user-male:before,
.icons8-chat:before,
.icons8-checked-user-male:before,
.icons8-checklist:before,
.icons8-checkmark:before,
.icons8-classroom:before,
.icons8-clock:before,
.icons8-coconut-cocktail:before,
.icons8-comments:before,
.icons8-confused:before,
.icons8-contact-card:before,
.icons8-contacts:before,
.icons8-crying:before,
.icons8-customer-support:before,
.icons8-customs-officer:before,
.icons8-dashboard:before,
.icons8-degrees:before,
.icons8-delete:before,
.icons8-delete-2:before,
.icons8-deleted-message:before,
.icons8-diploma-1:before,
.icons8-dislike:before,
.icons8-do-not-disturb:before,
.icons8-down:before,
.icons8-down-left:before,
.icons8-down-right:before,
.icons8-download:before,
.icons8-driver:before,
.icons8-edit-user-male:before,
.icons8-email:before,
.icons8-email-2:before,
.icons8-error:before,
.icons8-exit:before,
.icons8-external-link:before,
.icons8-feedback:before,
.icons8-final-state:before,
.icons8-find-and-replace:before,
.icons8-find-user-male:before,
.icons8-foam-fingers:before,
.icons8-gas-station:before,
.icons8-girl:before,
.icons8-give-way:before,
.icons8-give-way-filled:before,
.icons8-glasses:before,
.icons8-golf-ball:before,
.icons8-graduation-cap:before,
.icons8-graduation-cap-with-calendar:before,
.icons8-hand-cursor:before,
.icons8-handshake:before,
.icons8-happy:before,
.icons8-high-importance:before,
.icons8-hourglass:before,
.icons8-identity-theft:before,
.icons8-inactive-state:before,
.icons8-info:before,
.icons8-interstate-truck:before,
.icons8-iris-scan:before,
.icons8-left:before,
.icons8-like:before,
.icons8-location:before,
.icons8-low-importance:before,
.icons8-menu-2:before,
.icons8-message:before,
.icons8-moderator-male:before,
.icons8-money:before,
.icons8-money-bag:before,
.icons8-more:before,
.icons8-motorcycle:before,
.icons8-near-me:before,
.icons8-no-reminders:before,
.icons8-offline:before,
.icons8-ok:before,
.icons8-ok-2:before,
.icons8-one-way-transition:before,
.icons8-online:before,
.icons8-open-folder:before,
.icons8-overtime:before,
.icons8-pencil:before,
.icons8-phone:before,
.icons8-price-tag-usd:before,
.icons8-private:before,
.icons8-private-2:before,
.icons8-public:before,
.icons8-question:before,
.icons8-question-mark:before,
.icons8-rating:before,
.icons8-remove-user-male:before,
.icons8-renew-subscription:before,
.icons8-reply:before,
.icons8-report-card:before,
.icons8-restart:before,
.icons8-return:before,
.icons8-right:before,
.icons8-road:before,
.icons8-road-2:before,
.icons8-sad:before,
.icons8-scales:before,
.icons8-scooter:before,
.icons8-sent:before,
.icons8-services:before,
.icons8-shekel:before,
.icons8-shutdown:before,
.icons8-signpost:before,
.icons8-sleeping:before,
.icons8-speedometer:before,
.icons8-star:before,
.icons8-states:before,
.icons8-steering-wheel:before,
.icons8-steering-wheel-with-calendar:before,
.icons8-stethoscope:before,
.icons8-support:before,
.icons8-surprised:before,
.icons8-taxi:before,
.icons8-taxi-2:before,
.icons8-tie:before,
.icons8-timezone--1:before,
.icons8-timezone--1-2:before,
.icons8-timezone--10:before,
.icons8-timezone--10-2:before,
.icons8-timezone--5:before,
.icons8-timezone--5-2:before,
.icons8-topic-moved:before,
.icons8-tractor:before,
.icons8-traffic-jam:before,
.icons8-traffic-light:before,
.icons8-truck-filled:before,
.icons8-up:before,
.icons8-up-left:before,
.icons8-up-right:before,
.icons8-upload:before,
.icons8-usd:before,
.icons8-user-female:before,
.icons8-user-group-man-man:before,
.icons8-user-groups:before,
.icons8-user-male:before,
.icons8-user-menu-male:before,
.icons8-valet-parking:before,
.icons8-visible:before,
.icons8-whatsapp:before,
.icons8-whatsapp-2:before,
.icons8-wink:before {
  display: inline-block;
  font-family: "SivanPlusFont";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

.icons8-about:before {
  content: "\f100";
}
.icons8-active-state:before {
  content: "\f101";
}
.icons8-add-user-male:before {
  content: "\f102";
}
.icons8-address-book:before {
  content: "\f103";
}
.icons8-administrator-male:before {
  content: "\f104";
}
.icons8-angel:before {
  content: "\f105";
}
.icons8-ask-question:before {
  content: "\f106";
}
.icons8-attach:before {
  content: "\f107";
}
.icons8-baby-feet:before {
  content: "\f108";
}
.icons8-bag-diagonal-view:before {
  content: "\f109";
}
.icons8-bank:before {
  content: "\f10a";
}
.icons8-beach:before {
  content: "\f10b";
}
.icons8-bench-press:before {
  content: "\f10c";
}
.icons8-bill:before {
  content: "\f10d";
}
.icons8-book:before {
  content: "\f10e";
}
.icons8-book-with-calendar:before {
  content: "\f10f";
}
.icons8-boy:before {
  content: "\f110";
}
.icons8-bugle:before {
  content: "\f111";
}
.icons8-businessman:before {
  content: "\f112";
}
.icons8-businesswoman:before {
  content: "\f113";
}
.icons8-businesswoman-2:before {
  content: "\f114";
}
.icons8-cancel:before {
  content: "\f115";
}
.icons8-cancel-2:before {
  content: "\f116";
}
.icons8-cancel-2-2:before {
  content: "\f117";
}
.icons8-cancel-subscription:before {
  content: "\f118";
}
.icons8-cancel-with-usd:before {
  content: "\f119";
}
.icons8-car:before {
  content: "\f11a";
}
.icons8-change-user-male:before {
  content: "\f11b";
}
.icons8-chat:before {
  content: "\f11c";
}
.icons8-checked-user-male:before {
  content: "\f11d";
}
.icons8-checklist:before {
  content: "\f11e";
}
.icons8-checkmark:before {
  content: "\f11f";
}
.icons8-classroom:before {
  content: "\f120";
}
.icons8-clock:before {
  content: "\f121";
}
.icons8-coconut-cocktail:before {
  content: "\f122";
}
.icons8-comments:before {
  content: "\f123";
}
.icons8-confused:before {
  content: "\f124";
}
.icons8-contact-card:before {
  content: "\f125";
}
.icons8-contacts:before {
  content: "\f126";
}
.icons8-crying:before {
  content: "\f127";
}
.icons8-customer-support:before {
  content: "\f128";
}
.icons8-customs-officer:before {
  content: "\f129";
}
.icons8-dashboard:before {
  content: "\f12a";
}
.icons8-degrees:before {
  content: "\f12b";
}
.icons8-delete:before {
  content: "\f12c";
}
.icons8-delete-2:before {
  content: "\f12d";
}
.icons8-deleted-message:before {
  content: "\f12e";
}
.icons8-diploma-1:before {
  content: "\f12f";
}
.icons8-dislike:before {
  content: "\f130";
}
.icons8-do-not-disturb:before {
  content: "\f131";
}
.icons8-down:before {
  content: "\f132";
}
.icons8-down-left:before {
  content: "\f133";
}
.icons8-down-right:before {
  content: "\f134";
}
.icons8-download:before {
  content: "\f135";
}
.icons8-driver:before {
  content: "\f136";
}
.icons8-edit-user-male:before {
  content: "\f137";
}
.icons8-email:before {
  content: "\f138";
}
.icons8-email-2:before {
  content: "\f139";
}
.icons8-error:before {
  content: "\f13a";
}
.icons8-exit:before {
  content: "\f13b";
}
.icons8-external-link:before {
  content: "\f13c";
}
.icons8-feedback:before {
  content: "\f13d";
}
.icons8-final-state:before {
  content: "\f13e";
}
.icons8-find-and-replace:before {
  content: "\f13f";
}
.icons8-find-user-male:before {
  content: "\f140";
}
.icons8-foam-fingers:before {
  content: "\f141";
}
.icons8-gas-station:before {
  content: "\f142";
}
.icons8-girl:before {
  content: "\f143";
}
.icons8-give-way:before {
  content: "\f144";
}
.icons8-give-way-filled:before {
  content: "\f145";
}
.icons8-glasses:before {
  content: "\f146";
}
.icons8-golf-ball:before {
  content: "\f147";
}
.icons8-graduation-cap:before {
  content: "\f148";
}
.icons8-graduation-cap-with-calendar:before {
  content: "\f149";
}
.icons8-hand-cursor:before {
  content: "\f14a";
}
.icons8-handshake:before {
  content: "\f14b";
}
.icons8-happy:before {
  content: "\f14c";
}
.icons8-high-importance:before {
  content: "\f14d";
}
.icons8-hourglass:before {
  content: "\f14e";
}
.icons8-identity-theft:before {
  content: "\f14f";
}
.icons8-inactive-state:before {
  content: "\f150";
}
.icons8-info:before {
  content: "\f151";
}
.icons8-interstate-truck:before {
  content: "\f152";
}
.icons8-iris-scan:before {
  content: "\f153";
}
.icons8-left:before {
  content: "\f154";
}
.icons8-like:before {
  content: "\f155";
}
.icons8-location:before {
  content: "\f156";
}
.icons8-low-importance:before {
  content: "\f157";
}
.icons8-menu-2:before {
  content: "\f158";
}
.icons8-message:before {
  content: "\f159";
}
.icons8-moderator-male:before {
  content: "\f15a";
}
.icons8-money:before {
  content: "\f15b";
}
.icons8-money-bag:before {
  content: "\f15c";
}
.icons8-more:before {
  content: "\f15d";
}
.icons8-motorcycle:before {
  content: "\f15e";
}
.icons8-near-me:before {
  content: "\f15f";
}
.icons8-no-reminders:before {
  content: "\f160";
}
.icons8-offline:before {
  content: "\f161";
}
.icons8-ok:before {
  content: "\f162";
}
.icons8-ok-2:before {
  content: "\f163";
}
.icons8-one-way-transition:before {
  content: "\f164";
}
.icons8-online:before {
  content: "\f165";
}
.icons8-open-folder:before {
  content: "\f166";
}
.icons8-overtime:before {
  content: "\f167";
}
.icons8-pencil:before {
  content: "\f168";
}
.icons8-phone:before {
  content: "\f169";
}
.icons8-price-tag-usd:before {
  content: "\f16a";
}
.icons8-private:before {
  content: "\f16b";
}
.icons8-private-2:before {
  content: "\f16c";
}
.icons8-public:before {
  content: "\f16d";
}
.icons8-question:before {
  content: "\f16e";
}
.icons8-question-mark:before {
  content: "\f16f";
}
.icons8-rating:before {
  content: "\f170";
}
.icons8-remove-user-male:before {
  content: "\f171";
}
.icons8-renew-subscription:before {
  content: "\f172";
}
.icons8-reply:before {
  content: "\f173";
}
.icons8-report-card:before {
  content: "\f174";
}
.icons8-restart:before {
  content: "\f175";
}
.icons8-return:before {
  content: "\f176";
}
.icons8-right:before {
  content: "\f177";
}
.icons8-road:before {
  content: "\f178";
}
.icons8-road-2:before {
  content: "\f179";
}
.icons8-sad:before {
  content: "\f17a";
}
.icons8-scales:before {
  content: "\f17b";
}
.icons8-scooter:before {
  content: "\f17c";
}
.icons8-sent:before {
  content: "\f17d";
}
.icons8-services:before {
  content: "\f17e";
}
.icons8-shekel:before {
  content: "\f17f";
}
.icons8-shutdown:before {
  content: "\f180";
}
.icons8-signpost:before {
  content: "\f181";
}
.icons8-sleeping:before {
  content: "\f182";
}
.icons8-speedometer:before {
  content: "\f183";
}
.icons8-star:before {
  content: "\f184";
}
.icons8-states:before {
  content: "\f185";
}
.icons8-steering-wheel:before {
  content: "\f186";
}
.icons8-steering-wheel-with-calendar:before {
  content: "\f187";
}
.icons8-stethoscope:before {
  content: "\f188";
}
.icons8-support:before {
  content: "\f189";
}
.icons8-surprised:before {
  content: "\f18a";
}
.icons8-taxi:before {
  content: "\f18b";
}
.icons8-taxi-2:before {
  content: "\f18c";
}
.icons8-tie:before {
  content: "\f18d";
}
.icons8-timezone--1:before {
  content: "\f18e";
}
.icons8-timezone--1-2:before {
  content: "\f18f";
}
.icons8-timezone--10:before {
  content: "\f190";
}
.icons8-timezone--10-2:before {
  content: "\f191";
}
.icons8-timezone--5:before {
  content: "\f192";
}
.icons8-timezone--5-2:before {
  content: "\f193";
}
.icons8-topic-moved:before {
  content: "\f194";
}
.icons8-tractor:before {
  content: "\f195";
}
.icons8-traffic-jam:before {
  content: "\f196";
}
.icons8-traffic-light:before {
  content: "\f197";
}
.icons8-truck-filled:before {
  content: "\f198";
}
.icons8-up:before {
  content: "\f199";
}
.icons8-up-left:before {
  content: "\f19a";
}
.icons8-up-right:before {
  content: "\f19b";
}
.icons8-upload:before {
  content: "\f19c";
}
.icons8-usd:before {
  content: "\f19d";
}
.icons8-user-female:before {
  content: "\f19e";
}
.icons8-user-group-man-man:before {
  content: "\f19f";
}
.icons8-user-groups:before {
  content: "\f1a0";
}
.icons8-user-male:before {
  content: "\f1a1";
}
.icons8-user-menu-male:before {
  content: "\f1a2";
}
.icons8-valet-parking:before {
  content: "\f1a3";
}
.icons8-visible:before {
  content: "\f1a4";
}
.icons8-whatsapp:before {
  content: "\f1a5";
}
.icons8-whatsapp-2:before {
  content: "\f1a6";
}
.icons8-wink:before {
  content: "\f1a7";
}
